import React from "react";
import tw, { styled } from "twin.macro";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import Header from "@shared/components/Header";
import { MAIN_COLOR, SITE } from "../config";

const Container = styled.div`
  ${tw`p-5`}
`;
const GridContainer = styled.div`
  ${tw`grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 my-5 gap-5`}
`;
const GridItem = styled.div`
  ${tw`max-w-[250px] mx-auto sm:mx-0 mb-5`}
`;
const ImageContainer = styled.div`
  ${tw`relative mb-5`}
`;
const Position = styled.div`
  ${tw`whitespace-nowrap absolute p-2 text-white bg-[#EC1F27] top-3/4 -left-1.5 text-base`}
`;
const Name = styled.div`
  ${tw`text-2xl text-[#EC1F27] mb-2.5`}
`;
const Email = styled.a`
  ${tw`text-base hover:text-blue-600`}
`;

const FrontOffice = ({
  data: {
    markdownRemark: {
      frontmatter: { seo, title, hero, tabs, staff },
    },
  },
  location,
}) => {
  return (
    <Layout
      seoTitle={seo?.title || title}
      seoDescription={seo?.description}
      location={location}
    >
      <Container>
        {title && (
          <Header
            title={title}
            heroData={hero}
            tabsData={tabs}
            color={MAIN_COLOR}
            site={SITE}
          />
        )}
        <GridContainer>
          {staff.map((staff, idx) => (
            <GridItem key={idx}>
              <ImageContainer>
                <GatsbyImage
                  image={getImage(staff.image)}
                  alt="staff"
                  style={{ height: "100%" }}
                />
                <Position>{staff.position}</Position>
              </ImageContainer>
              <Name>{staff.name}</Name>
              <Email href={`mailto:${staff.email}`}>{staff.email}</Email>
            </GridItem>
          ))}
        </GridContainer>
      </Container>
    </Layout>
  );
};

export default FrontOffice;

export const pageQuery = graphql`
  query FrontOfficeQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        seo {
          title
          description
        }
        title
        hero {
          pageImage {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          pageImagePosition
        }
        tabs {
          label
          url
          icon
        }
        staff {
          name
          image {
            childImageSharp {
              gatsbyImageData(height: 250, aspectRatio: 1)
            }
          }
          position
          email
        }
      }
    }
  }
`;
